$content_width: 80%;
$max_content_width: 800px;
$background_color: #FFF;
$text_color: #555;
$text_fontsize:1.1rem;
$link_color:#1994FF;
//$link_color:#ff1e00;
$link_highlight:#78C0FF;
//$link_highlight:#ffae00;
$link_softcolor:#aaa;
$link_darkcolor:#999;
$hr_lightcolor:#ccc;
$code_border_color:#E3E7E8;
$code_background:#3b404b;
$code_background_inline:#a8a8a8;
$code_background_darker:#FDFDFD;
$ml_background:#DDD;


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

body {
    font-family: 'Barlow',"Source Serif Pro", Baskerville, serif;
    background-color:$background_color;
    line-height:1;
    font-size:$text_fontsize;
    color:$text_color;
}

h1 {
    font-size:1.7em;
}
h2 {
    font-size:1.5em;
}
h3 {
    font-size:1.3em;
}
h4 {
    font-size:0.9em;
	background: #eee;
	padding: 5px 5px;
    @include border-radius(4px);
}

a {
    color:$link_color;
    text-decoration:none;
}

a:hover {
    color:$link_highlight;
}

a.softlink {
    color:$link_softcolor;
    text-decoration:none;
}

a.softlink:hover {
    color:$link_highlight;
}
 
a.darklink {
    color:$text_color;
    text-decoration:none;
}

a.darklink:hover {
    color:$link_highlight;
} 

div#overallcolumn {
    margin:50px auto;
    width:$content_width;
    max-width:$max_content_width;
}

/* Header */

#logo {
    background:$background_color url(/imgs/logo.png) repeat-x 0 0 / 126px 52px;
    display:block;
    float:left;
    width:126px;
    height:52px;
    color: $link_darkcolor;
    text-indent:-2000px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) { 
    #logo {
        background:$background_color url(/imgs/logo@2x.png) repeat-x 0 0 / 126px 52px;
    }
}


div#header {
    margin:0 0 15px;
    padding:0 0 5px;

    ol {
        float:right;
        list-style:none;
        margin: 13px 0;
    }
    ul {
        float:right;
        list-style:none;
        margin: 5px 0;
    }
    li {
        float:left;
        margin-left: 20px;
    }
}


/* Post page */

a.ptitle{
    color:$text_color;
    font-weight:500;
}

div.post {
    margin:90px 0 0;
    line-height:1.55;
    text-align: justify;
    hyphens: auto;

    h1 {
        margin:1px 0;
        line-height:1.2;
    }

    h2 {
        margin-top:50px;
        line-height:1.2;
    }

    h3 {
        margin-top:30px;
        line-height:1.2;
    }
    
    table{
        width: 90%;
        margin: 45px auto;
        font-size: 0.9em;
        border-collapse: collapse;
        vertical-align:top;
        text-align: left;
        hyphens: none;
        border:1px solid darkgrey;
        thead,th{
            background-color:#eee;
        }
        tr,td,th{
            vertical-align:top;
            border:1px solid darkgrey;
            padding: 5px;
        }
        tr,td{
           background-color:#FDFDFD;
        }
    }
 
    p.date{
        font-family: Palatino, serif;
        font-style:italic;
        font-weight: 300;
        font-size: 1em;
        color:$link_softcolor;
        margin-top: 0;
        margin-bottom: 40px;
    }

    p.comments{
        margin-top: 50px;
    }

    p.share{
        text-align:right;
        margin-bottom: 20px;
    }

    li{
        list-style:disc outside;
    }

    object{ 
        display:block;
        margin: 10px auto;
        padding: 5px 5px;
        @include border-radius(5px);
        border: 1px solid $code_border_color;
        background-color:$code_background;
    }

	img{
        display:block;
		margin: 30px auto;
        @include border-radius(5px);
	}
	.imgcaption{
        display:block;
		margin-bottom: 40px;
		text-align: center;
		font-size: 0.8em;
		font-weight: 700;
	}

    code.inlinecode {
        font-family: Inconsolata, Consolas, "Courier New", monospace;
        background-color: #d9d9d9;
        padding: 2px 4px;
        font-size: 0.9em;
        hyphens: none;
        @include border-radius(3px);
        -webkit-font-smoothing: antialiased;
    }

    pre code{
        font-family: Inconsolata, Consolas, "Courier New", monospace;
        display:block;
        overflow-x:auto;
        overflow-y:hidden;
        hyphens: none;
        background-color:$code_background;
        border: 1px solid $code_border_color;
        @include border-radius(5px);
        padding: 0 5px 15px 15px;
        margin: 15px auto;
        font-size:0.95em;
        line-height:1;
    }
}

div#related {
    margin-top: 50px;
}

.post-nav {
    margin-top:50px;
    color:$link_color;
    font-size: 0.8em;

    .previous-post {
        float: left;
        text-align:left;
        width: 50%;
        i{
           margin-right: 5px;
        }
    }
    
    .next-post {
        float: right;
        text-align:right;
        width: 50%;
        i{
            margin-left: 5px;
        }
    }
}

/* Archive pages */

div.archive{

    margin:50px 0 0;
    line-height:1.7;

    ul {
        margin: 5px 0;
        list-style:none
    }

    li {
        margin-left:-15px;
    }

    h1{
        margin: 5px 0;

        a{
            color:$link_darkcolor;
            font-weight: bold;
            font-size:1em;
        }
    }

    h1.pagetitle {
        margin-bottom: 30px;
    }

    a {
        color:$text_color;
        text-decoration:none;
    }

    a:hover {
        color:$link_highlight;
    }

    .architem_title{
        margin-top: 15px;
        margin-bottom: 0;

        a{
            font-weight: bold;
            font-size:1.2em;
        }
    }

    .architem_date{
        float:right;
        color:$link_softcolor;
    }

    .architem_desc{
        color:$link_softcolor;
    }

    .architem_desc a{
        color:$link_softcolor;
    }

    .architem_desc a:hover{
        color:$link_highlight;
    }

    .architem_group{
        color:$link_softcolor;
    }


    table.archt{
        width: 100%;
        vertical-align:top;
        border:0px;

        div.archy {
            color:$link_softcolor;
            font-size:2em;
            font-weight: 300;
            margin-top:40px;
            margin-bottom:5px;
            line-height: 1em;
        }

        div.archy a{
            color:$link_softcolor;
            font-weight: 300;
            line-height: 1em;
            font-size:0.9em;
        }

        div.archy a:hover{
            color:$link_highlight;
            font-weight: 300;
            line-height: 1em;
            font-size:0.9em;
        }

        .lnk{
           text-align:left;
        }

        .dat{
            color:$link_softcolor;
            width:25%;
            text-align:right;
            font-size: 0.9em;
        }
    }
}

div.archive a.override {
    color:$link_color;
    text-decoration:none;
}

div.archive a.override:hover {
    color:$link_highlight;
}

/* Footer */

div#desc {
    float:left;
    color:$link_darkcolor;
    margin-top:70px;
    font-size:0.8em;
    line-height:50%;
}

/* Other Stuff */

hr {
    clear:both;
    visibility:hidden
}

hr.line{
    border: 0;
    height: 1px;
    background: $hr_lightcolor;
    visibility: visible;
}

hr.cleft {
    clear:left
}

hr.cright {
    clear:right
}

/* Quote */

/* Normal */
div.post{
    blockquote {
        display:block;
        border-left: 4px solid $link_color;
        margin: 10px 20px;
        padding: 1px 10px;
        background: none;
        font-style: italic;
        line-height: 1.4 !important;
    }
}
 
/*
Add citation in markdown with:

<cite>
<span>His Book</span><br/>
Benjamin Franklin</cite>

*/

div.post{
    cite {
        color: $link_darkcolor;
        display: block;
        font-size: .8em; 
        margin-top: 30px;
		text-align: right;
        line-height: 1.2 !important;
      
        span {
            color: $link_softcolor;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-shadow: 0 1px white; 
        }
    }
}

/* Classic style */

div.post{
    blockquote.classic {
        border: 0;
        margin: 60px 70px;
        padding: 0;
        display:block;
        background: none;
        color: $text_color;
        font-family: Palatino, serif;
        font-size: 1.1em;
        font-style: italic;
        line-height: 1.4 !important;
        position: relative;
        z-index: 600;

        * {
            box-sizing: border-box; 
        }

        p {
            line-height: 1.4 !important;
        }

        p:first-child:before {
            font-family: Georgia, serif;
            content: '\201C';
            color: $link_softcolor;
            font-size: 7.5em;
            font-weight: 700;
            opacity: .3;
            position: absolute;
            top: -.4em;
            left: -.2em;    
            text-shadow: none;
            z-index: -300;
        }
    }
}

/*
Add citation in markdown with:

<cite>
<span>His Book</span><br/>
Benjamin Franklin</cite>

*/

div.post{
    cite.classic {
        font-family: Georgia, serif;
        color: $link_darkcolor;
        display: block;
        font-size: .6em; 
        margin-top: 30px;
      
        span {
            color: $link_softcolor;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-shadow: 0 1px white; 
        }
    }
}

/* Newsletter */
div.post {
    #mc_embed_signup{
        @include border-radius(5px);
        border: 1px solid $code_border_color;
        background-color:$ml_background;
		font-size: 0.9em;

	    form {
			padding: 10px 10px;
		}
		h3{
			margin: 5px 0;
		}

		span{
			display:block;
			margin-bottom: 15px;
		}

		.email{
			display: inline;
			@include border-radius(4px);
			margin: 0 2% 10px 0;
			font-size: 0.9em;
		}
		.button{
			display: inline;
			background-color: $link_color;
			min-width: 30px;
			width: 20%;
		}
	}
}

/* Specific devices media queries */
/* iPhone 6,7,8 */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    #announce {
        display: none;
    }
    #logo {
        float: none;
        margin: 10px auto;
    }
    div#header {
        ol {
            padding-inline-start: 0;
            float: left;
        }
        ul {
            padding-inline-start: 0;
        }
        li {
            margin-left: 5px;
        }
    }
}

/* iPhone 5 */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
    #announce {
        display: none;
    }
    #logo {
        float: none;
        margin: 10px auto;
    }
    div#header {
        ol {
            margin: 10px auto;
            padding-inline-start: 0;
        }
        ul {
            padding-inline-start: 0;
        }
        li {
            margin-left: 5px;
        }
    }
}

/* Share icons */

.shareicon {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    vertical-align: top;
}

// Font awesome
@font-face {
    font-family: 'fontello';
    src: url('/font/fontello.eot?9676732');
    src: url('/font/fontello.eot?9676732#iefix') format('embedded-opentype'),
    url('/font/fontello.woff?9676732') format('woff'),
    url('/font/fontello.ttf?9676732') format('truetype'),
    url('/font/fontello.svg?9676732#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fa {
    font-family: "fontello";    
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
}

.fa-chevron-left:before { content: '\e800'; } /* '' */
.fa-chevron-right:before { content: '\e801'; } /* '' */  

/* Announcement bar */

#announce{
	width: 100%;
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
	display: none; /* Starts hidden, then check for variable in localStorage */
	position: fixed;
    margin-left: -8px;
	top: 0;
	text-align: center;
    font-family: monospace;
	font-size: 11px;
    letter-spacing: 1px;
}

#announce a{
    color: #fff;
    text-decoration:underline;
    font-weight: normal;
}

#announce-close{
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
	text-align: center;
    font-family: monospace;
	font-size: 11px;
    letter-spacing: 1px;
}

#announce-close a{
    color: #fff;
    text-decoration: none;
    font-weight: normal;
}


/* Link icons */

@mixin linkicon($data) {
  background-image:url($data);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 0.7em;
  width: 0.7em;
  padding-left: 5px;
  color: $link_color;
  opacity: 0.4;
}
 

a[href$=".pdf"]::after
{
  @include linkicon('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48cGF0aCBkPSJNMTgxLjkgMjU2LjFjLTUtMTYtNC45LTQ2LjktMi00Ni45IDguNCAwIDcuNiAzNi45IDIgNDYuOXptLTEuNyA0Ny4yYy03LjcgMjAuMi0xNy4zIDQzLjMtMjguNCA2Mi43IDE4LjMtNyAzOS0xNy4yIDYyLjktMjEuOS0xMi43LTkuNi0yNC45LTIzLjQtMzQuNS00MC44ek04Ni4xIDQyOC4xYzAgLjggMTMuMi01LjQgMzQuOS00MC4yLTYuNyA2LjMtMjkuMSAyNC41LTM0LjkgNDAuMnpNMjQ4IDE2MGgxMzZ2MzI4YzAgMTMuMy0xMC43IDI0LTI0IDI0SDI0Yy0xMy4zIDAtMjQtMTAuNy0yNC0yNFYyNEMwIDEwLjcgMTAuNyAwIDI0IDBoMjAwdjEzNmMwIDEzLjIgMTAuOCAyNCAyNCAyNHptLTggMTcxLjhjLTIwLTEyLjItMzMuMy0yOS00Mi43LTUzLjggNC41LTE4LjUgMTEuNi00Ni42IDYuMi02NC4yLTQuNy0yOS40LTQyLjQtMjYuNS00Ny44LTYuOC01IDE4LjMtLjQgNDQuMSA4LjEgNzctMTEuNiAyNy42LTI4LjcgNjQuNi00MC44IDg1LjgtLjEgMC0uMS4xLS4yLjEtMjcuMSAxMy45LTczLjYgNDQuNS01NC41IDY4IDUuNiA2LjkgMTYgMTAgMjEuNSAxMCAxNy45IDAgMzUuNy0xOCA2MS4xLTYxLjggMjUuOC04LjUgNTQuMS0xOS4xIDc5LTIzLjIgMjEuNyAxMS44IDQ3LjEgMTkuNSA2NCAxOS41IDI5LjIgMCAzMS4yLTMyIDE5LjctNDMuNC0xMy45LTEzLjYtNTQuMy05LjctNzMuNi03LjJ6TTM3NyAxMDVMMjc5IDdjLTQuNS00LjUtMTAuNi03LTE3LTdoLTZ2MTI4aDEyOHYtNi4xYzAtNi4zLTIuNS0xMi40LTctMTYuOXptLTc0LjEgMjU1LjNjNC4xLTIuNy0yLjUtMTEuOS00Mi44LTkgMzcuMSAxNS44IDQyLjggOSA0Mi44IDl6Ii8+PC9zdmc+');
}

a[href*="wikipedia.org/wiki/"]::after,
a[href*="wiki.c2.com/"]::after
{    
  @include linkicon('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48cGF0aCBkPSJNNjQwIDUxLjJsLS4zIDEyLjJjLTI4LjEuOC00NSAxNS44LTU1LjggNDAuMy0yNSA1Ny44LTEwMy4zIDI0MC0xNTUuMyAzNTguNkg0MTVsLTgxLjktMTkzLjFjLTMyLjUgNjMuNi02OC4zIDEzMC05OS4yIDE5My4xLS4zLjMtMTUgMC0xNS0uM0MxNzIgMzUyLjMgMTIyLjggMjQzLjQgNzUuOCAxMzMuNCA2NC40IDEwNi43IDI2LjQgNjMuNC4yIDYzLjdjMC0zLjEtLjMtMTAtLjMtMTQuMmgxNjEuOXYxMy45Yy0xOS4yIDEuMS01Mi44IDEzLjMtNDMuMyAzNC4yIDIxLjkgNDkuNyAxMDMuNiAyNDAuMyAxMjUuNiAyODguNiAxNS0yOS43IDU3LjgtMTA5LjIgNzUuMy0xNDIuOC0xMy45LTI4LjMtNTguNi0xMzMuOS03Mi44LTE2MC05LjctMTcuOC0zNi4xLTE5LjQtNTUuOC0xOS43VjQ5LjhsMTQyLjUuM3YxMy4xYy0xOS40LjYtMzguMSA3LjgtMjkuNCAyNi4xIDE4LjkgNDAgMzAuNiA2OC4xIDQ4LjEgMTA0LjcgNS42LTEwLjggMzQuNy02OS40IDQ4LjEtMTAwLjggOC45LTIwLjYtMy45LTI4LjYtMzguNi0yOS40LjMtMy42IDAtMTAuMy4zLTEzLjYgNDQuNC0uMyAxMTEuMS0uMyAxMjMuMS0uNnYxMy42Yy0yMi41LjgtNDUuOCAxMi44LTU4LjEgMzEuN2wtNTkuMiAxMjIuOGM2LjQgMTYuMSA2My4zIDE0Mi44IDY5LjIgMTU2LjdMNTU5LjIgOTEuOGMtOC42LTIzLjEtMzYuNC0yOC4xLTQ3LjItMjguM1Y0OS42bDEyNy44IDEuMS4yLjV6Ii8+PC9zdmc+');
}

a[href$='.js']::after,
a[href$='.c']::after,
a[href$='.h']::after,
a[href$='.cpp']::after,
a[href$='.java']::after,
a[href$='.json']::after,
a[href$='.sh']::after
{
  @include linkicon('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48cGF0aCBkPSJNMzg0IDEyMS45NDFWMTI4SDI1NlYwaDYuMDU5YzYuMzY1IDAgMTIuNDcgMi41MjkgMTYuOTcxIDcuMDI5bDk3Ljk0MSA5Ny45NDFBMjQuMDA1IDI0LjAwNSAwIDAgMSAzODQgMTIxLjk0MXpNMjQ4IDE2MGMtMTMuMiAwLTI0LTEwLjgtMjQtMjRWMEgyNEMxMC43NDUgMCAwIDEwLjc0NSAwIDI0djQ2NGMwIDEzLjI1NSAxMC43NDUgMjQgMjQgMjRoMzM2YzEzLjI1NSAwIDI0LTEwLjc0NSAyNC0yNFYxNjBIMjQ4ek0xMjMuMjA2IDQwMC41MDVhNS40IDUuNCAwIDAgMS03LjYzMy4yNDZsLTY0Ljg2Ni02MC44MTJhNS40IDUuNCAwIDAgMSAwLTcuODc5bDY0Ljg2Ni02MC44MTJhNS40IDUuNCAwIDAgMSA3LjYzMy4yNDZsMTkuNTc5IDIwLjg4NWE1LjQgNS40IDAgMCAxLS4zNzIgNy43NDdMMTAxLjY1IDMzNmw0MC43NjMgMzUuODc0YTUuNCA1LjQgMCAwIDEgLjM3MiA3Ljc0N2wtMTkuNTc5IDIwLjg4NHptNTEuMjk1IDUwLjQ3OWwtMjcuNDUzLTcuOTdhNS40MDIgNS40MDIgMCAwIDEtMy42ODEtNi42OTJsNjEuNDQtMjExLjYyNmE1LjQwMiA1LjQwMiAwIDAgMSA2LjY5Mi0zLjY4MWwyNy40NTIgNy45N2E1LjQgNS40IDAgMCAxIDMuNjggNi42OTJsLTYxLjQ0IDIxMS42MjZhNS4zOTcgNS4zOTcgMCAwIDEtNi42OSAzLjY4MXptMTYwLjc5Mi0xMTEuMDQ1bC02NC44NjYgNjAuODEyYTUuNCA1LjQgMCAwIDEtNy42MzMtLjI0NmwtMTkuNTgtMjAuODg1YTUuNCA1LjQgMCAwIDEgLjM3Mi03Ljc0N0wyODQuMzUgMzM2bC00MC43NjMtMzUuODc0YTUuNCA1LjQgMCAwIDEtLjM3Mi03Ljc0N2wxOS41OC0yMC44ODVhNS40IDUuNCAwIDAgMSA3LjYzMy0uMjQ2bDY0Ljg2NiA2MC44MTJhNS40IDUuNCAwIDAgMS0uMDAxIDcuODc5eiIvPjwvc3ZnPg==');
}

a[href$='.gif']::after,
a[href$='.ico']::after,
a[href$='.jpg']::after,
a[href$='.png']::after,
a[href$='.svg']::after
{
  @include linkicon('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48cGF0aCBkPSJNMzg0IDEyMS45NDFWMTI4SDI1NlYwaDYuMDU5YTI0IDI0IDAgMCAxIDE2Ljk3IDcuMDI5bDk3Ljk0MSA5Ny45NDFhMjQuMDAyIDI0LjAwMiAwIDAgMSA3LjAzIDE2Ljk3MXpNMjQ4IDE2MGMtMTMuMiAwLTI0LTEwLjgtMjQtMjRWMEgyNEMxMC43NDUgMCAwIDEwLjc0NSAwIDI0djQ2NGMwIDEzLjI1NSAxMC43NDUgMjQgMjQgMjRoMzM2YzEzLjI1NSAwIDI0LTEwLjc0NSAyNC0yNFYxNjBIMjQ4em0tMTM1LjQ1NSAxNmMyNi41MSAwIDQ4IDIxLjQ5IDQ4IDQ4cy0yMS40OSA0OC00OCA0OC00OC0yMS40OS00OC00OCAyMS40OTEtNDggNDgtNDh6bTIwOCAyNDBoLTI1NmwuNDg1LTQ4LjQ4NUwxMDQuNTQ1IDMyOGM0LjY4Ni00LjY4NiAxMS43OTktNC4yMDEgMTYuNDg1LjQ4NUwxNjAuNTQ1IDM2OCAyNjQuMDYgMjY0LjQ4NWM0LjY4Ni00LjY4NiAxMi4yODQtNC42ODYgMTYuOTcxIDBMMzIwLjU0NSAzMDR2MTEyeiIvPjwvc3ZnPg==');
}

@import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');

#map {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    z-index: 100;
}
